/* Sticky Header */
.mainHeader {
  padding: 12px 18px;
  background: white;
  color: black;
  text-align: center;
  width: 100vw;
  max-width: 100%;
  z-index: 9;
}
.fixedTop {
  width: 100vw;
  max-width: 100%;
  padding: 12px 18px;
  position: fixed;
  top: 0;
  background: white;
}
.fixedTop + .main-block {
  padding-top: 102px;
}

.title{
  color: black;
  width: 80vw;
}
.description{
  color: black;
  width: 80vw;
  font-size:1rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .example {display:flex;flex-direction:column;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .example {display:flex;flex-direction:column;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .example {display:flex;flex-direction:row;}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .example {display:flex;flex-direction:row;}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .example {display:flex;flex-direction:row;}
}

.text-footer{
  font-size: clamp(0.50rem, 0.75vw, 2rem);
  color: black;
}
.text-header{
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: black;
}

.main-page-container {
  margin: 0 auto;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  top:0;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.react-pdf__Document {
  display: inline-block;
}

.react-pdf__Page {
  width: 410;
  height: 520;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App, .footer {
  text-align: center;
  width: 100vw;
  max-width: 100%;
  margin: 0rem auto;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* pemisah dari desain awal */
a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: #000000;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: #000000;
  background-color: transparent;
  text-decoration: underline;
}

a:href {
  color: #5c5c5c;
  text-decoration: none;
}
.Link {
  color: #000000;
}
.Link:hover {
  color: #000000;
}
.secondary-button {
  padding: 10px;
  border-radius: 10px;
  background-color: grey;
  outline: none;
  border: 1;
  border-color: #000000;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: 400;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: white;  
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  top:0;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.primary-button-product {
  padding: 0.9rem 1.75rem;
  background-color: #hhhhhh;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.25rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 1900px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 3vw, 4rem);
  color: #000000;
  /* line-height: 5rem; */
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-heading2 {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-subheading {
  font-size: clamp(1.5rem, 4vw, 2rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-text {
  font-size: clamp(1rem, 2.7vw, 1.3rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 4rem;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .Hidden-Button {
    display: none;
  }
}